<template>
  <v-card
    class="p-4"
    outlined
    elevation="7"
    :to="!disableNav ? `/users/${value._id}` : ''"
    v-on:click="() => $emit('click')"
  >
    <div></div>
    <!-- this line is needed to make the profile image rounded due to default styling -->
    <div
      class="bg-center bg-cover bg-no-repeat h-48 w-48 rounded-lg m-auto relative"
      :class="{ 'inactive-user-image': !value.isEmployed }"
      :style="{
        'background-image': `url('${
          value.profileImageUrl || missingProfileImage
        }')`,
        'background-color': !value.profileImageUrl ? '#3b938d' : 'initial',
      }"
    >
      <div
        class="inactive-user-text flex align-center justify-center bg-dark text-light absolute left-0 right-0 h-8 text-lg font-bold"
        v-if="!value.isEmployed"
      >
        <div>Inactive</div>
      </div>
    </div>

    <div class="my-4 px-4 text-center">
      <div class="text-xl">{{ value.firstName }} {{ value.lastName }}</div>
    </div>

    <UserCapacityIndicator :value="value.capacity" />

    <div class="mt-4 p-2 bg-gray-200 text-center">
      <div class="text-md">{{ value.jobTitle }}</div>
    </div>
  </v-card>
</template>
<script>
import UserCapacityIndicator from "./UserCapacityIndicator";
import missingProfileImage from "../../assets/images/missing_profile.svg";

export default {
  name: "UserCard",
  components: {
    UserCapacityIndicator,
  },
  props: {
    value: Object,
    disableNav: { type: Boolean, default: false },
  },
  data: () => ({
    missingProfileImage,
  }),
};
</script>
<style>
.inactive-user-image {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.inactive-user-text {
  bottom: 10px;
}
</style>