<template>
  <div class="flex">
    <div class="flex-1 mr-2">
      <v-progress-linear class="first" :class="valueChangeUp ? 'change-up' : 'change-down'" :value="first"></v-progress-linear>
    </div>
    <div class="flex-1 mr-2">
      <v-progress-linear class="second" :class="valueChangeUp ? 'change-up' : 'change-down'"  :value="second"></v-progress-linear>
    </div>
    <div class="flex-1">
      <v-progress-linear class="third" :class="valueChangeUp ? 'change-up' : 'change-down'"  :value="third"></v-progress-linear>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserCapacityIndicator",
  props: {
    value: Number,
  },
  data: () => ({
    valueChangeUp: true
  }),
  watch: {
    value: function(newValue, oldValue) {
      if (newValue > oldValue) {
        this.valueChangeUp = true
      } else {
        this.valueChangeUp = false
      }
    }
  },
  computed: {
    first() {
      if (this.value > (1 / 3)) {
        return 100;
      } else {
        return this.value * 100 * 3;
      }
    },
    second() {
      if (this.value > 2 / 3) {
        return 100;
      } else {
        return (this.value  - (1 / 3)) * 300;
      }
    },
    third() {
      if (this.value >= 100) {
        return 100;
      } else {
        return (this.value - (2 / 3)) * 300;
      }
    },
  },
};
</script>
<style>
.first .v-progress-linear__background.primary, .second .v-progress-linear__background.primary, .third .v-progress-linear__background.primary {
  /* background-color: #42c4b6 !important */
  background-color: #636363 !important
}

.first .v-progress-linear__determinate.primary {
  /* background-color: #346264 !important; */
  background-color: #42c4b6 !important;
}

.first.change-up {
  transition-delay: 0;
}

.first.change-down {
  transition-delay: 0.4s;
}

.second .v-progress-linear__determinate.primary {
  /* background-color: #3b938d !important; */
  background-color: #42c4b6 !important;
  transition-delay: 0.2s;
}

.third .v-progress-linear__determinate.primary {
  background-color: #42c4b6 !important;
}

.third.change-up {
  transition-delay: 0.4s;
}

.third.change-down {
  transition-delay: 0;
}
</style>